/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Layout, FormContainer } from '../EditWellnessJourney/styles';
import { PageTitle } from '../CreateEvent/styles';
import { addWellnessJourney } from '../../redux/actions';
import {withTranslation} from 'react-i18next';
import Form from '../Form';
import Waiting from '../Waiting';

const stateForAdd = {
  title: '',
  description: '',
  image: '',
  imageName: '',
  journey_category_id: '',
  selectedOption: 0,
  themeName: '',
  themeMonth: '',
  theme_id: null
};

class AddWellnessJourney extends Component {
  constructor() {
    super();
    this.state = {
      showLoader: false,
    };
  }
  onSubmit = (e, data) => {
    let payload = {
      description:data.description,
      image:data.image,
      imageName:data.imageName,
      imageUpdated:data.imageUpdated,
      journey_category_id:data.journey_category_id,
      levels: data.level && data.level.level ? data.level.level : null,
      question_id: data.question && data.question.id ? data.question.id: null,
      wellness_goal_id: data.wellness_goal && data.wellness_goal.id ? data.wellness_goal.id : null,
      selectedOption:data.selectedOption ? data.selectedOption : null,
      themeMonth:data.themeMonth ? data.themeMonth : null,
      themeName:data.themeName ? data.themeName: null,
      theme_id:data.themeId ? data.themeId : null,
      title:data.title
    }
    const { addWellnessJourney, history, isAdminRoute } = this.props;
    e.preventDefault();
    this.setState({
      showLoader: true
    })
    addWellnessJourney(payload, history, isAdminRoute);
  };

  render() {
    const { adventureCategories,themes,t, themesHeaderAll, isAdminRoute } = this.props;
    if (this.state.showLoader && isAdminRoute) {
      return <Waiting />;
    }
    const addJourney ={
      "fields": [
        {
          "name": "title",
          "type": "text",
          "label": t("Journey Title"),
          "placeholder": t("Title"),
          "mandatory": true,
        },
        {
          "name": "image",
          "type": "file",
          "label": t("Journey Image"),
          "placeholder": t("Journey Photo"),
          "mandatory": true,
        },
        {
          "name": "journey_category_name",
          "type": "drop-down",
          "label": t("Journey Category"),
          "placeholder": t("Select Journey Category"),
          "key": "journey_category_id",
          "mandatory": true,
        },
        {
          "name": "wellness_goal",
          "type": "wellness_goal",
          "label": t("Wellness Goal"),
          "placeholder": t("Select Wellness Goal"),
          "key": "wellness_goal",
          "mandatory": false,
        },
        {
          "name": "sub_level",
          "type": "sub_level",
          "label": t("Sub Level (WBA Questions)"),
          "placeholder": t("Select Journey Category"),
          "key": "question",
          "mandatory": false,
        },
        {
          "name": "level",
          "type": "level",
          "label": t("Level"),
          "placeholder": t("Select Level"),
          "key": "level",
          "mandatory": false,
        },
        {
          "name": "description",
          "type": "text",
          "label": t("Journey Description"),
          "placeholder": t("Description"),
          "mandatory": true,
        }
      ],
      "button": t("Create Journey"),
      "mandatoryFields": ["title", "image", "journey_category_id", "description"]
    }
    return (
      <Layout>
        <PageTitle>{t('Create Journey')}</PageTitle>
        <FormContainer>
          <Form
            fields={addJourney.fields}
            buttonText={addJourney.button}
            state={stateForAdd}
            mandatoryFields={addJourney.mandatoryFields}
            onSubmit={this.onSubmit}
            journey_category_id={adventureCategories}
            themes={themes}
            themesHeaderAll={themesHeaderAll}
          />
        </FormContainer>
      </Layout>
    )
  }
}

AddWellnessJourney.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adventureCategories: PropTypes.array.isRequired,
  addWellnessJourney: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  themes: PropTypes.array.isRequired,
  t: PropTypes.func,
  themesHeaderAll: PropTypes.array,
  isAdminRoute: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoading: state.education.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  addWellnessJourney: (data, history, isAdminRoute) => dispatch(addWellnessJourney(data, history, isAdminRoute))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddWellnessJourney)));