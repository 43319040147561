import styled from 'styled-components';
import {Modal} from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-dialog { 
    .modal-header {
      border-bottom: 0px;
      background: linear-gradient( 
        180deg,#52A8ED 0%,#8AD3F7 100%);
    } 
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 25px;
  .close {
    float: right;
    margin-top: -20px;
    margin-right: -16px;
  }
`;

const StyledBody = styled(Modal.Body)`
  padding: 0px 25px 25px 25px;
`;

const Container = styled.div`
  width: 70%;
  margin-left: 15%;
  position: relative;
  .headerName{
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5px;
  }
  .subTitle{
    font-size: 14px;
    color: #adadad;
    text-align: center;
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

const CheckBoxContainer = styled.div`
  width: 50%;
  display: inline-block;
  padding: 5px;
  position: inherit;
  @media (max-width: 720px) {
    width: 100%;
    margin-bottom: 10px;
  }
  > div{
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      display: inline-block;
    }
  }
  .check {
    width: 20%;
    @media (max-width: 720px) {
      width: 15%;
    }
  }
  .svg {
    width: 20%;
    @media (max-width: 720px) {
      width: 15%;
    }
  }
  .name {
    width: 60%;
    font-size: 13px;
    color: #adadad;
    @media (max-width: 720px) {
      width: 70%;
    }
  }
`;

const InputContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  background-color: white;
  border: none;
  border: 1px solid rgb(220,220,220);
  border-radius: 5px;
  outline: none;
  box-shadow: none;
  padding: 15px;
  font-size: 14px;
  color: rgb(139,139,139);
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
`;

const StyledSvg = styled.svg`
  height: 30px;
  width: 40px;
  float: left;
  fill: ${({fill}) => fill ? fill : '#999999'};
  padding-top: 3px;
`;

const CheckMark = styled.span`
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  position: relative;
  height: 24px;
  width: 24px;
  background-color: #efefef;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: left;
  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 8px;
    top: 2px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    border-color: ${({ checked }) => checked ? '#0BA4E0' : '#efefef'};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .biometric-button {
    font-size: 16px;
  }
`;

const StyledBodyV2 = styled(Modal.Body)`
  padding: 0px 0px 0px 0px;
`;

const SubmitContainerV2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom:25px;
  
  .biometric-button {
    font-size: 16px;
  }
`;

export { StyledModal, StyledBody, StyledHeader, Container, CheckBoxContainer, InputContainer, 
  StyledInput, StyledSvg, CheckMark, SubmitContainer, StyledBodyV2, SubmitContainerV2};
