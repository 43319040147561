import React from 'react';
import PropTypes from 'prop-types';

import { StyledModal, StyledBody, StyledHeader, Container, CheckBoxContainer, InputContainer, 
  StyledInput, StyledSvg, CheckMark, SubmitContainer } from './styles';
// import { SaveButton } from '../CreateEvent/styles';
import CommonButton from '../common/CommonButton/CommonButton';

const CheckBoxData = [
  {
    checked: false,
    name: 'Biometric screenings',
    svg: 'M8,3.44c-4.32,-7.48 -15.61,3.63 0,12.56c15.61,-8.93 4.32,-20.03 0,-12.56z',
    viewBox: '0 0 30 16',
    fill: '#fd5d65'
  },
  {
    checked: false,
    name: 'Lunch and Learn',
    fill: '#93bf3d',
    svg: 'M10.51,7.37c0.4,-0.65 1.04,-1.57 1.84,-2.46h0c0.68,-0.75 2.01,-1.72 2.01,-1.72c0.2,-0.15 0.18,-0.35 -0.05,-0.45l-1.61,-0.72c-0.23,-0.1 -0.54,-0.03 -0.69,0.17c0,0 -1.19,1.65 -2.59,5.15c-4.32,-1.89 -8.43,0.76 -8.43,5.35c0,4.8 3.83,12.1 9,9.9c5.45,2.28 9.01,-5.09 9.01,-9.9c0,-4.64 -3.69,-7.36 -8.5,-5.31zM9.54,5.81c0.25,-0.02 0.47,-0.23 0.49,-0.47c0,0 0.16,-1.88 -1.2,-3.19c-1.36,-1.31 -3.3,-1.15 -3.3,-1.15c-0.25,0.02 -0.47,0.23 -0.49,0.47c0,0 -0.16,1.88 1.19,3.19c1.36,1.31 3.3,1.15 3.3,1.15z',
    viewBox: '0 0 38 28'
  },
  {
    checked: false,
    name: 'Company Challenge',
    viewBox: '0 0 35 25',
    fill: '#fbad19',
    svg: 'M5.685,21.649 C4.248,18.682 5.013,16.982 6.118,15.380 C7.327,13.626 7.639,11.890 7.639,11.890 C7.639,11.890 8.590, 13.116 8.210,15.035 C9.890,13.179 10.207,10.223 9.953,9.092 C13.751,11.724 15.373,17.424 13.186,21.649 C24.819,15.119 16.080, 5.350 14.558,4.249 C15.066,5.350 15.162,7.212 14.137,8.117 C12.403,1.593 8.115,0.255 8.115,0.255 C8.622,3.620 6.276,7.299 4.014, 10.048 C3.935,8.706 3.850,7.781 3.139,6.497 C2.980,8.934 1.102,10.921 0.594,13.362 C-0.095,16.669 1.109,19.091 5.685,21.649 Z'
  },
  {
    checked: false,
    name: 'Wellness Event',
    viewBox: '0 0 30 16',
    fill: '#159fc9',
    svg: 'M11.53,1.14c-0.26,-0.22 -0.63,-0.22 -0.89,0c-0.56,0.49 -3.36,3.1 -3.36,6.34c0,3.24 2.8,5.85 3.36,6.34c0.13,0.11 0.29,0.17 0.45,0.17c0.16,0 0.32,-0.06 0.44,-0.17c0.56,-0.49 3.36,-3.1 3.36,-6.34c0,-3.24 -2.8,-5.85 -3.36,-6.34zM20.67,9.34c-0.16,-0.1 -0.56,-0.32 -1.09,-0.53c-0.17,-0.07 -0.35,0.04 -0.37,0.22c-0.16,1.24 -0.59,2.74 -1.66,3.83c-0.05,0.05 -0.1,0.1 -0.15,0.14c-0.22,0.2 -0.01,0.57 0.27,0.48c0.57,-0.19 1.06,-0.42 1.49,-0.7c1.45,-0.94 1.69,-2.12 1.78,-2.56c0.01,-0.03 0.01,-0.06 0.02,-0.08c0.1,-0.31 -0.02,-0.64 -0.29,-0.8zM18.58,6.33c-0.03,-0.34 -0.29,-0.6 -0.62,-0.63c-0.3,-0.02 -1.05,-0.06 -1.97,0.05c-0.17,0.02 -0.27,0.19 -0.23,0.35c0.11,0.41 0.17,0.84 0.17,1.3c0,2.56 -1.46,4.7 -2.65,6.03c-0.18,0.2 -0.01,0.52 0.25,0.47c1.11,-0.21 2.37,-0.66 3.32,-1.63c1.93,-1.98 1.78,-5.3 1.73,-5.95zM4.59,13.01c-0.05,-0.05 -0.1,-0.09 -0.15,-0.14c-1.07,-1.09 -1.5,-2.59 -1.66,-3.83c-0.02,-0.18 -0.21,-0.29 -0.37,-0.22c-0.53,0.21 -0.93,0.43 -1.09,0.53c-0.28,0.16 -0.4,0.5 -0.29,0.8c0,0.02 0.01,0.05 0.02,0.08c0.09,0.44 0.33,1.62 1.78,2.56c0.43,0.28 0.92,0.51 1.49,0.7c0.28,0.09 0.49,-0.27 0.27,-0.48zM8.71,13.45c-1.2,-1.33 -2.65,-3.47 -2.65,-6.03c0,-0.46 0.06,-0.9 0.17,-1.3c0.04,-0.17 -0.06,-0.33 -0.23,-0.35c-0.91,-0.12 -1.66,-0.08 -1.97,-0.05c-0.33,0.03 -0.59,0.29 -0.62,0.63c-0.05,0.65 -0.2,3.97 1.73,5.95c0.94,0.97 2.2,1.42 3.32,1.63c0.26,0.05 0.43,-0.27 0.25,-0.47z'
  },
  {
    checked: false,
    name: 'Health Fair',
    viewBox: '0 0 33 22',
    fill: '#fd5d65',
    svg: 'M7,4.5v12c0,1.93 1.57,3.5 3.5,3.5c1.93,0 3.5,-1.57 3.5,-3.5v-12c0,-1.93 -1.57,-3.5 -3.5,-3.5c-1.93,0 -3.5,1.57 -3.5,3.5zM16.5,7h-12c-1.93,0 -3.5,1.57 -3.5,3.5c0,1.93 1.57,3.5 3.5,3.5h12c1.93,0 3.5,-1.57 3.5,-3.5c0,-1.93 -1.57,-3.5 -3.5,-3.5z'
  },
  {
    checked: false,
    name: 'Company 5k run',
    viewBox: '0 0 36 22',
    fill: '#fd5d65',
    svg: 'M13.53,16.71v0c-0.15,0 -0.29,-0.05 -0.41,-0.15c-0.01,-0.01 -0.03,-0.03 -0.04,-0.04l-10.97,-11.2l-0.66,0.67c-0.29,0.3 -0.45,0.69 -0.45,1.1c0,0.42 0.16,0.81 0.45,1.1l6.77,6.9h-1.29c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h2.56l1.27,1.3h-5.52c-0.35,0 -0.63,0.29 -0.63,0.65c0,0.36 0.28,0.65 0.63,0.65h7.05h10.08c0.35,0 0.63,-0.29 0.63,-0.65v-1.64h-9.47zM19.68,11.3h-1.6c-0.03,0.01 -0.05,0.02 -0.08,0.03l-3.1,0.97c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l2.36,-0.73l-0.51,-0.52l-3.01,0.94c-0.06,0.02 -0.12,0.03 -0.19,0.03c-0.27,0 -0.52,-0.18 -0.61,-0.46c-0.1,-0.34 0.09,-0.7 0.42,-0.81l3.15,-0.98c0.21,-0.97 -0.08,-1.98 -0.78,-2.69l-0.68,-0.69c-0.45,-0.46 -1.05,-0.71 -1.68,-0.71c-0.3,0 -0.58,-0.12 -0.78,-0.33l-0.42,-0.43c-0.21,-0.21 -0.33,-0.5 -0.33,-0.8c0,-0.65 -0.25,-1.26 -0.7,-1.72l-0.93,-0.95c-0.15,-0.16 -0.38,-0.22 -0.59,-0.17l-2.35,0.55c-0.12,0.03 -0.22,0.09 -0.31,0.17l-3.59,3.67l10.78,11.01h9.21v-0.72c0,-1.87 -1.49,-3.39 -3.32,-3.39z'
  },
  {
    checked: false,
    name: 'Flue shot',
    viewBox: '0 0 39 23',
    fill: '#159fc9',
    svg: 'M1.13 18.07L4.92 21.86C5.11 22.04 5.41 22.04 5.6 21.86C5.79 21.67 5.79 21.37 5.6 21.18L5.03 20.61L8.18 17.47L10.18 19.47C10.37 19.66 10.67 19.66 10.86 19.47C11.05 19.29 11.05 18.98 10.86 18.8L9.76 17.7L18.99 8.5C19.08 8.41 19.13 8.29 19.13 8.16C19.13 7.15 18.76 6.23 18.17 5.49L21.86 1.81C22.04 1.63 22.04 1.33 21.86 1.14C21.76 1.05 21.64 1 21.52 1C21.4 1 21.28 1.05 21.18 1.14L17.5 4.82C16.76 4.23 15.84 3.86 14.83 3.86C14.7 3.86 14.58 3.91 14.49 4L5.26 13.21L4.17 12.12C4.07 12.02 3.95 11.98 3.83 11.98C3.71 11.98 3.58 12.02 3.49 12.12C3.3 12.3 3.3 12.6 3.49 12.79L5.57 14.87L2.42 18.01L1.81 17.4C1.71 17.3 1.59 17.26 1.47 17.26C1.34 17.26 1.22 17.3 1.13 17.4C0.94 17.58 0.94 17.88 1.13 18.07ZM15.93 7.34C16.02 7.25 16.15 7.2 16.27 7.2C16.39 7.2 16.51 7.25 16.61 7.34L17.46 8.2C17.65 8.39 17.65 8.69 17.46 8.87C17.27 9.06 16.97 9.06 16.79 8.87L15.93 8.02C15.74 7.83 15.74 7.53 15.93 7.34ZM14.5 9.73C14.59 9.64 14.71 9.59 14.84 9.59C14.96 9.59 15.08 9.64 15.17 9.73L15.55 10.11C15.74 10.29 15.74 10.6 15.55 10.78C15.37 10.97 15.06 10.97 14.88 10.78L14.5 10.4C14.31 10.22 14.31 9.92 14.5 9.73ZM12.11 11.16C12.2 11.07 12.33 11.02 12.45 11.02C12.57 11.02 12.69 11.07 12.79 11.16L13.64 12.02C13.83 12.2 13.83 12.51 13.64 12.69C13.46 12.88 13.15 12.88 12.97 12.69L12.11 11.84C11.92 11.65 11.92 11.35 12.11 11.16ZM10.68 13.55C10.77 13.45 10.89 13.41 11.02 13.41C11.14 13.41 11.26 13.45 11.35 13.55L11.73 13.93C11.92 14.11 11.92 14.41 11.73 14.6C11.55 14.79 11.24 14.79 11.06 14.6L10.68 14.22C10.49 14.04 10.49 13.73 10.68 13.55Z'
  },
  {
    checked: false,
    name: 'other',
    viewBox: '0 0 32 20',
    fill: '#fbad19',
    svg: 'M16.41 9.89C16.8 9.45 17 8.93 17 8.33C17 7.79 16.8 7.32 16.39 6.93C15.99 6.53 15.51 6.33 14.96 6.33L12.02 6.33C12.05 6.24 12.07 6.15 12.1 6.08C12.13 6.01 12.17 5.94 12.22 5.85C12.27 5.77 12.3 5.71 12.33 5.67C12.45 5.43 12.55 5.24 12.62 5.08C12.68 4.93 12.75 4.72 12.82 4.46C12.89 4.19 12.92 3.93 12.92 3.67C12.92 3.5 12.92 3.36 12.91 3.26C12.91 3.15 12.89 3 12.86 2.79C12.83 2.58 12.79 2.41 12.73 2.27C12.68 2.13 12.6 1.97 12.48 1.8C12.36 1.63 12.22 1.49 12.05 1.38C11.89 1.27 11.67 1.18 11.42 1.11C11.16 1.03 10.87 1 10.54 1C10.36 1 10.2 1.06 10.06 1.2C9.92 1.34 9.8 1.51 9.7 1.72C9.6 1.93 9.53 2.11 9.49 2.26C9.45 2.41 9.41 2.62 9.36 2.89C9.3 3.19 9.25 3.4 9.22 3.52C9.18 3.65 9.12 3.82 9.03 4.03C8.94 4.24 8.83 4.4 8.7 4.53C8.47 4.76 8.11 5.18 7.63 5.78C7.28 6.22 6.92 6.65 6.56 7.04C6.19 7.44 5.92 7.64 5.75 7.66C5.57 7.67 5.42 7.74 5.29 7.87C5.16 8 5.1 8.15 5.1 8.32L5.1 15C5.1 15.18 5.17 15.34 5.3 15.46C5.44 15.59 5.6 15.66 5.78 15.67C6.03 15.67 6.59 15.83 7.46 16.13C8 16.31 8.43 16.44 8.74 16.54C9.05 16.63 9.48 16.73 10.03 16.84C10.58 16.95 11.09 17 11.56 17L11.74 17L12.55 17L12.93 17C13.87 16.99 14.57 16.72 15.02 16.19C15.43 15.71 15.61 15.08 15.54 14.3C15.82 14.05 16.01 13.72 16.12 13.32C16.24 12.9 16.24 12.49 16.12 12.1C16.44 11.68 16.59 11.21 16.57 10.68C16.57 10.45 16.52 10.19 16.41 9.89ZM3.74 7.67L0.68 7.67C0.5 7.67 0.34 7.73 0.2 7.86C0.07 8 0 8.15 0 8.33L0 15C0 15.18 0.07 15.34 0.2 15.47C0.34 15.6 0.5 15.67 0.68 15.67L3.74 15.67C3.92 15.67 4.08 15.6 4.22 15.47C4.35 15.34 4.42 15.18 4.42 15L4.42 8.33C4.42 8.15 4.35 8 4.22 7.86C4.08 7.73 3.92 7.67 3.74 7.67ZM2.52 14.14C2.38 14.27 2.22 14.33 2.04 14.33C1.85 14.33 1.69 14.27 1.56 14.14C1.43 14.01 1.36 13.85 1.36 13.67C1.36 13.49 1.43 13.33 1.56 13.2C1.69 13.07 1.85 13 2.04 13C2.22 13 2.38 13.07 2.52 13.2C2.65 13.33 2.72 13.49 2.72 13.67C2.72 13.85 2.65 14.01 2.52 14.14Z'
  }
]

class AddWellnessInitiative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: CheckBoxData
    }
  }

  changeCheckData = (index) => {
    let changeData = this.state.data;
    changeData[index].checked = !changeData[index].checked;
    this.setState({
      data: changeData
    })
  }

  showCheckboxData = (checkBoxData) => (
    checkBoxData.map((data, index) => (
      <CheckBoxContainer key={index}>
        <div>
          <div className="check">
            <CheckMark checked={data.checked} onClick={() => this.changeCheckData(index)}/>
          </div>
          <div className="svg">
            <StyledSvg  xmlns="http://www.w3.org/2000/svg" viewBox={data.viewBox} fill={data.fill}>
              <path d={data.svg} />
            </StyledSvg>
          </div>
          <div className="name">{data.name}</div>
        </div>
      </CheckBoxContainer>
    ))
  )

  render() {
    const { show } = this.props;
    const { data } = this.state;
    return(
      <StyledModal show={show} onHide={this.props.showModalPopUp}>
        <StyledHeader closeButton>
          <Container>
            <div className="headerName">
              Wellness Initiative
            </div>
            <div className="subTitle">
              Add new wellness initiative
            </div>
          </Container>
        </StyledHeader>
        <StyledBody>
          <Container>
            {this.showCheckboxData(data)}
            <InputContainer>
              <StyledInput type="text" placeholder='Initiative Name' />
              <StyledInput type="date" placeholder='Starting Date' />
              <StyledInput type="date" placeholder='Endind Date' />
            </InputContainer>
            <SubmitContainer>
              {/* <SaveButton color="#159fc9" onClick={this.props.showModalPopUp}>
                Submit
              </SaveButton> */}
              <CommonButton
                btnType={"rounded"}
                onClick={this.props.showModalPopUp}
                styles={{color:"#159fc9"}}
                title={'Submit'}
              />
            </SubmitContainer>
          </Container>
        </StyledBody>
      </StyledModal>
    );
  }
}

AddWellnessInitiative.propTypes = {
  show: PropTypes.bool,
  showModalPopUp: PropTypes.func
}

export default AddWellnessInitiative;